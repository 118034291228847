// Colors
$whiteColor: #fff;
$primaryColor: #0073a8;
$secondColor: #10b5cb;
$gradientColor: linear-gradient(to right, #10b5cb, #0073a8);
$blackColor: #1b2d36;
$textColor: #58585f;
$whiteColorDean: #ededed;
$greyTextColor: #bebebe;
$inputColor: #efefef;
$cardBgColor: #e1e1eb;
$cardHoverColor: #fff;
$bodyColor: #f0f0f6;
$homeOverlayGb: #2f6a7f;
